/* AuditEdit.css */
.react-table {
  font-size: 14px;
  border-collapse: collapse;
  width: 100%;
}

.react-table input[type='text'],
.react-table textarea {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.react-table th {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  vertical-align: bottom;
}

.react-table td {
  padding: 16px;
  vertical-align: top;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.react-table .-pagination {
  padding: 20px 0;
  text-align: right;
  border-top: 1px solid rgba(224, 224, 224, 1);
}

.react-table .-pagination button {
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  padding: 6px 12px;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
}

.react-table .-pagination button:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.react-table .-filter input {
  font-size: 14px;
  padding: 8px;
  border: none;
  width: 100%;
  box-sizing: border-box;
}

.react-table .-filter input:focus {
  outline: none;
  box-shadow: 0 1px 0 0 #3f51b5;
}

.toggle-nodes,
button {
  background-color: #3f51b5;
  border: none;
  color: white;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 5px;
  border-radius: 4px;
  outline: none;
  text-transform: uppercase;
}

.toggle-nodes:hover,
button:hover {
  background-color: #283593;
}

.nodes-container {
  position: relative;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.overlay-button {
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

.overlay-button:hover {
  background-color: #0056b3;
}
